/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

a {
  color: var(--bs-primary);
}
body {
  font-family: "Roboto", sans-serif !important;
}
iframe {
  display: none !important;
}
.form-label {
  font-weight: 600;
}
.btn-gradiant {
  background: linear-gradient(
    90deg,
    rgba(70, 70, 181, 1) 8%,
    rgba(203, 42, 122, 1) 41%
  );
  background: rgb(70, 70, 181);
}
.LoginmainBg {
  background-size: cover;
}

.pass-err {
  color: rgb(253, 59, 59) !important;
  padding-top: 5px !important;
  font-size: 12px;
}

.spin-reg {
  margin-top: 30px;
}

.spin-div {
  text-align: center;
  display: flex;
  justify-content: center;
}

.auth-custom-btn {
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.otp-select-btn {
  color: #5e5873 !important;
  background-color: transparent !important;
}

.phone-input {
  width: 100% !important;
}
.dataTable-filter {
  max-width: 256px !important;
}
.fFGrAy,
.izzqyT {
  min-height: auto !important;
}
.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  height: auto !important;
}
[dir] .react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  padding: 0.4rem 0.5rem !important;
}
[dir] .react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
  padding: 0.72rem 0.5rem !important;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 9px;
  background: #f1f1f1;
  border-left: 1px solid darken(rgba(0, 0, 0, 0.075), 60%);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: darken(#949494, 90%);
  border-radius: 10px;
  background: #949494;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: auto;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

[dir="ltr"] legend {
  float: inherit;
}

.cursor-ponter {
  cursor: pointer;
}

[dir] .react-tel-input .flag-dropdown {
  padding: 0;
  background-color: transparent !important;
  border: unset !important;
}

[dir] .react-tel-input .selected-flag:hover,
[dir] .react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}
[dir] .react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent !important;
}

[dir] .dark-layout .react-tel-input .country-list {
  padding: 0;
  background-color: #161d31 !important;
}

[dir] .dark-layout .react-tel-input .flag-dropdown.open .selected-flag {
  background: #161d31;
}

[dir] .react-tel-input .form-control:focus {
  border-color: var(--bs-primary) !important;
}
[dir] .react-tel-input .form-control {
  padding: 0.571rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  height: 38px;
}
.category-btn a {
  color: var(--bs-primary) !important;
}
.border-primary-hover:hover {
  border: 1px solid var(--bs-primary) !important;
}
.dark-layout .react-dataTable .rdt_TableBody .rdt_TableRow button svg {
  color: inherit !important;
}
.shadow-0 {
  box-shadow: none !important;
}
.shadow-hover:hover {
  box-shadow: 0 4px 24px 0 rgb(24 24 24 / 10%);
}

.triggerCard {
  min-width: 22rem;
  width: 25rem;
  min-height: 7rem;
}

.triggerActionFieldCard {
  cursor: pointer;
  border-radius: 0.42rem;
  background-color: rgba(130, 134, 139, 0.06);
  border: 1px solid #ebe9f1;
}
.triggerActionFieldCard.active {
  background-color: rgba(203, 42, 122, 0.12);
  border-color: var(--bs-primary);
}

.triggerActionFieldCard {
  position: relative;
  z-index: 1;
}

.triggerActionFieldCard::after {
  content: "";
  width: 1px !important;
  height: 42px !important;
  position: absolute !important;
  bottom: -42px !important;
  margin-left: -1px;
  left: 50% !important;
  margin-left: -1px;
  background: #e4e0e2;
  z-index: 0;
}
.triggerActionFieldCard.active::after {
  background: var(--bs-primary);
}
.triggerActionFieldCard:last-child:after {
  display: none;
}

body {
  --background-color: #fff;
}
body.dark-layout {
  --background-color: #283046;
}
.dark-layout .form-switch .form-check-input:not(:checked):not(:focus) {
  //background-image: unset !important;
  background-image: url("data:image/svg+xml,%3csvg width='26px' height='26px' viewBox='0 0 26 27' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cdefs%3e%3ccircle id='path-1' cx='8' cy='8' r='8'%3e%3c/circle%3e%3cfilter x='-40.6%' y='-21.9%' width='168.8%' height='168.8%' filterUnits='objectBoundingBox' id='filter-2'%3e%3cfeOffset dx='-1' dy='2' in='SourceAlpha' result='shadowOffsetOuter1'%3e%3c/feOffset%3e%3cfeGaussianBlur stdDeviation='1.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'%3e%3c/feGaussianBlur%3e%3cfeColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0' type='matrix' in='shadowBlurOuter1'%3e%3c/feColorMatrix%3e%3c/filter%3e%3c/defs%3e%3cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='switches-dot' transform='translate%285.000000, 5.000000%29' fill-rule='nonzero'%3e%3cg id='Oval'%3e%3cuse fill='black' fill-opacity='1' filter='url%28%23filter-2%29' xlink:href='%23path-1'%3e%3c/use%3e%3cuse fill='%23fff' xlink:href='%23path-1'%3e%3c/use%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
}

.dark-layout .Trigercontent {
  background-color: #333b50 !important;
}

.swal2-title {
  color: #595959 !important;
}
.min-w-3 {
  min-width: 3rem !important;
}
.css-1s2u09g-control {
  background-color: transparent;
  border-color: #4b4b4b !important;
}
.css-1pahdxg-control {
  background-color: transparent;
  border-color: var(--bs-primary) !important;
  box-shadow: none !important;
}
.triggerCardVariablePlan {
  width: 10rem;
  min-height: 7rem;
}
.form-switch.andOrTriggerSwitch .form-check-input {
  width: 4rem;
}

[dir] .andOrTriggerSwitch.form-switch .form-check-input:not(:checked) {
  border-color: var(--bs-primary);
  background-color: var(--bs-primary);
}

// Data table styles - Start
.react-dataTable > header {
  background: transparent;
  & .sc-fzpjYC,
  & .bIYKQe {
    background: transparent;
  }
}
// Data table styles - End
// .css-1k73pl0-control:hover, .css-adg1dw-control:hover {
//   border: 1px solid var(--bs-primary) !important;
// }