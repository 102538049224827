@import "../node_modules/prismjs/themes/prism-tomorrow";
@import "@core/scss/react/libs/tables/react-dataTable-component.scss";

// @import './assets/scss/app.scss';

.auth-custom-btn {
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.menu-item {
  border-radius: 40px !important;
}
.menu-item-link {
  line-height: 1.68 !important;
}
.active-menu-item {
  color: white !important;
  border-radius: 5px;
  background: linear-gradient(118deg, var(--bs-primary), #539bcb);
  line-height: 1.68 !important;
}
.category-btn {
  padding: 0;
  background-color: transparent !important;
  border-color: transparent !important;
  margin: 0 !important;
  padding: 0 !important;
}
.category-active {
  background-color: var(--bs-primary) !important;
  color: white !important;
}

.react-checkbox-tree .rct-icon {
  color: var(--bs-primary) !important;
}
.rct-node-icon {
  color: var(--bs-primary) !important;
}
.swal2-actions {
  gap: 2rem !important;
}

.error-label {
  margin-top: 10px !important;
  font-size: "10px" !important;
  color: red !important;
}
.anticon svg {
  color: var(--bs-primary) !important;
  display: inline-block;
}

a {
  color: var(--bs-primary);
  text-decoration: none;
}

.form-check-input {
  position: relative !important;
}
.toggle-switch-loader-right {
  position: absolute !important;
  font-size: 2px;
  height: 12px;
  width: 12px;
  top: 7px;
  left: 24px;
  transition: 0.1s;
}
.toggle-switch-loader-left {
  position: absolute !important;
  font-size: 2px;
  height: 12px;
  width: 12px;
  left: 6px;
  top: 7px;
  transition: 0.1s;
}
.login-alert {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
}
.sections-counter-container {
  counter-reset: my-sec-counter;
}

.sections-counter-item::before {
  counter-increment: my-sec-counter 1;
  content: "Section " counter(my-sec-counter) " ";
}

.beacons-counter-container {
  counter-reset: my-sec-counter;
}

.beacons-counter-item::before {
  counter-increment: my-sec-counter 1;
  content: "Beacon " counter(my-sec-counter) " ";
}

.empty-main-container {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 200px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  width: 100%;
}
body {
  --background-color: #fff;
}
body.dark-layout {
  --background-color: #283046;
}
.dark-layout .form-switch .form-check-input:not(:checked):not(:focus) {
  //background-image: unset !important;
  background-image: url("data:image/svg+xml,%3csvg width='26px' height='26px' viewBox='0 0 26 27' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cdefs%3e%3ccircle id='path-1' cx='8' cy='8' r='8'%3e%3c/circle%3e%3cfilter x='-40.6%' y='-21.9%' width='168.8%' height='168.8%' filterUnits='objectBoundingBox' id='filter-2'%3e%3cfeOffset dx='-1' dy='2' in='SourceAlpha' result='shadowOffsetOuter1'%3e%3c/feOffset%3e%3cfeGaussianBlur stdDeviation='1.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'%3e%3c/feGaussianBlur%3e%3cfeColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0' type='matrix' in='shadowBlurOuter1'%3e%3c/feColorMatrix%3e%3c/filter%3e%3c/defs%3e%3cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='switches-dot' transform='translate%285.000000, 5.000000%29' fill-rule='nonzero'%3e%3cg id='Oval'%3e%3cuse fill='black' fill-opacity='1' filter='url%28%23filter-2%29' xlink:href='%23path-1'%3e%3c/use%3e%3cuse fill='%23fff' xlink:href='%23path-1'%3e%3c/use%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
}

.hidden-text {
  visibility: hidden !important;
}

// AgGridReact rows styling

.ag-center-cols-container {
  width: 100% !important;
}

// custom Loader styling

.is-loading {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background-color: #eceaea;
    background-image: url("https://www.5balloons.info/wp-content/uploads/2020/05/ajax-loader.gif");
    background-size: 50px;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10000000;
    opacity: 0.4;
    filter: alpha(opacity=40);
  }
}

// react-checkbox-tree styling below

$rct-icon-color: $primary !default;
$rct-icon-font-family: "Font Awesome 5 Free", "FontAwesome", sans-serif;
$rct-label-hover: rgba($rct-icon-color, 0.1) !default;
$rct-label-active: rgba($rct-icon-color, 0.15) !default;
$rct-clickable-hover: rgba($rct-icon-color, 0.1) !default;
$rct-clickable-focus: rgba($rct-icon-color, 0.2) !default;

// Force ASCII output until Sass supports it
// https://github.com/sass/dart-sass/issues/568
@function unicode($str) {
  @return unquote('"') + unquote(str-insert($str, "\\", 1)) + unquote('"');
}

.react-checkbox-tree {
  display: flex;
  flex-direction: row-reverse;
  font-size: 16px;

  > ol {
    flex: 1 1 auto;
  }

  ol {
    margin: 0;
    padding-left: 0;
    list-style-type: none;

    ol {
      padding-left: 24px;
    }
  }

  button {
    line-height: normal;
    color: inherit;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .rct-bare-label {
    cursor: default;
  }

  label {
    margin-bottom: 0;
    cursor: pointer;

    &:hover {
      background: $rct-label-hover;
    }

    &:active,
    &:focus {
      background: $rct-label-active;
    }
  }

  &:not(.rct-native-display) input {
    display: none;
  }

  &.rct-native-display input {
    margin: 0 5px;
  }

  .rct-icon {
    display: inline-block;
    text-align: center;
    text-rendering: auto;
    font-family: $rct-icon-font-family;
    font-weight: normal;
    font-variant: normal;
    font-style: normal;
  }
}

.rct-disabled > .rct-text > label {
  opacity: 0.75;
  cursor: not-allowed;

  &:hover {
    background: transparent;
  }

  &:active {
    background: transparent;
  }
}

.rct-text {
  display: flex;
  align-items: center;
}

.rct-options {
  flex: 0 0 auto;
  margin-left: 0.5rem;
  text-align: right;
}

.rct-option {
  opacity: 0.75;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0 4px;
  font-size: 18px;

  &:hover {
    opacity: 1;
  }

  + .rct-option {
    margin-left: 2px;
  }
}

.rct-collapse,
.rct-checkbox,
.rct-node-icon {
  padding: 0 5px;

  * {
    display: inline-block;
    margin: 0;
    width: 14px;
  }
}

.rct-collapse {
  align-self: stretch;
  border: 0;
  background: none;
  line-height: normal;
  color: inherit;
  font-size: 12px;

  &.rct-collapse-btn {
    cursor: pointer;
  }

  > .rct-icon-expand-close {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}

.rct-native-display .rct-checkbox {
  display: none;
}

.rct-node-clickable {
  cursor: pointer;

  &:hover {
    background: $rct-clickable-hover;
  }

  &:focus {
    outline: 0;
    background: $rct-clickable-focus;
  }
}

.rct-node-icon {
  color: $rct-icon-color;
}

.rct-title {
  padding: 0 5px;
}

// Font Awesome 4 style
.rct-icons-fa4 {
  .rct-icon-expand-close::before {
    content: unicode("f054");
  }

  .rct-icon-expand-open::before {
    content: unicode("f078");
  }

  .rct-icon-uncheck::before {
    content: unicode("f096");
  }

  .rct-icon-check::before {
    content: unicode("f046");
  }

  .rct-icon-half-check::before {
    opacity: 0.5;
    content: unicode("f046");
  }

  .rct-icon-leaf::before {
    content: unicode("f016");
  }

  .rct-icon-parent-open::before {
    content: unicode("f115");
  }

  .rct-icon-parent-close::before {
    content: unicode("f114");
  }

  .rct-icon-expand-all::before {
    content: unicode("f0fe");
  }

  .rct-icon-collapse-all::before {
    content: unicode("f146");
  }
}

// Font Awesome 5 style
.rct-icons-fa5 {
  .rct-icon-expand-close::before {
    font-weight: 900;
    content: unicode("f054");
  }

  .rct-icon-expand-open::before {
    font-weight: 900;
    content: unicode("f078");
  }

  .rct-icon-uncheck::before {
    content: unicode("f0c8");
  }

  .rct-icon-check::before {
    content: unicode("f14a");
  }

  .rct-icon-half-check::before {
    opacity: 0.5;
    content: unicode("f14a");
  }

  .rct-icon-leaf::before {
    content: unicode("f15b");
  }

  .rct-icon-parent-open::before {
    content: unicode("f07c");
  }

  .rct-icon-parent-close::before {
    content: unicode("f07b");
  }

  .rct-icon-expand-all::before {
    content: unicode("f0fe");
  }

  .rct-icon-collapse-all::before {
    content: unicode("f146");
  }
}

// RTL support
.rct-direction-rtl {
  direction: rtl;

  ol ol {
    padding-right: 24px;
    padding-left: 0;
  }

  &.rct-icons-fa4 .rct-icon-expand-close::before {
    content: unicode("f105");
  }

  &.rct-icons-fa5 .rct-icon-expand-close::before {
    content: unicode("f053");
  }
}

.reorder-container {
  background-size: 40px 40px;
  background-image: radial-gradient(circle, #000000 1px, rgba(0, 0, 0, 0) 1px);
  background-attachment: fixed;
  // width: 100vw;
  overflow: auto;
  padding: 1rem;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 18px;
  }

  ul {
    position: relative;
    width: 300px;
  }

  li {
    border-radius: 10px;
    background: var(--bs-primary);
    margin-bottom: 12px;
    width: 100%;
    padding: 0.8rem 0.8rem;
    position: relative;
    color: white;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    cursor: grab;
  }
}

.react-dataTable {
  header {
    padding: 1rem !important;
  }
}

// Create Forms styling below

.question_form {
  height: 100%;
  width: 100%;
  padding-bottom: 30px;
}

.section {
  margin: auto;
  width: 50%;
}

.question_title_section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-top: 8px solid $primary;
  border-radius: 8px;
  padding: 30px 25px;
  margin: 10px;
}

.question_form_top {
  width: 100%;
  text-transform: capitalize;
}

.question_form_topright {
  margin: 10px;
  margin-left: auto;
}

.question_form_top_name {
  box-sizing: border-box;
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 2rem;
  font-weight: 400;
  line-height: 40px;
  line-height: 135%;
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid #f4f4f9;
  height: 35px;
}

.question_form_top_desc {
  box-sizing: border-box;
  margin-top: 10px;
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 40px;
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid #f4f4f9;
  height: 20px;
}

.question_boxes {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.add_question {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  width: 93%;
  margin-left: 10px;
}

.add_question_top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.question {
  box-sizing: border-box;
  margin-top: 10px;
  margin-bottom: 5px;
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  flex: 1;
  line-height: 40px;
  width: 40%;
  min-width: 200px;
  border: none;
  outline: none;
  color: black;
  height: 40px;
  margin-right: 10px;
  line-height: 10px;
  padding: 10px;
}

.question:focus {
  border-bottom: 1px solid $primary;
  //background-color: #F4F4F9;
}

.select {
  height: 40px;
  width: 230px;
  padding: 5px 15px;
  color: black;
  margin-left: 10px;
  margin-right: 10px;
  //border: 1.5px solid #F4F4F9;
  border-radius: 3px;
  background: transparent;
}

.menuitem {
  color: rgba(118, 118, 118, 0.3);
}

.add_question_body {
  display: flex;
  align-items: center;
}

.text_input {
  outline: none;
  border: none;
  height: 40px;
  width: 100%;
  font-family: Roboto, Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.2px;
  color: #202124;
}

.text_input:focus {
  border-bottom: 1.5px solid $primary;
}

.add_footer {
  display: flex;
  justify-content: space-between;
}

.add_question_bottom {
  margin-top: 12px;
  border-top: 1px solid rgba(118, 118, 118, 0.3);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.add_question_bottom_left {
  margin-top: 12px;
  border-top: 1px solid rgba(118, 118, 118, 0.3);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.add_border {
  border-left: 6px solid $primary;
}

.question_edit {
  //background-color: #F4F4F9;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 12px;
  margin-top: 10px;
  margin-bottom: 0;
  padding: 5px 1px;
  border-radius: 3px;
}

.edit {
  color: #5f6368;
}

@media (max-width: 900px) {
  .section {
    width: 100%;
  }
}

// CUSTOM THREE DOTS LOADER STYLE STARTS

.three-dots-loading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

// CUSTOM THREE DOTS LOADER STYLE STARTS

// CUSTOM STYLING OF MODAL STARTS

.custom-modal-body-styles {
  max-height: 50vh;
  overflow: auto;
}

// CUSTOM STYLING OF MODAL ENDS

// CUSTOM STYLING OF JSON INPUT TEXTAREA STARTS

.custom-json-text-area-styles {
  height: 400px;
  background-color: #141823 !important;
}

// CUSTOM STYLING OF JSON INPUT TEXTAREA ENDS

// CUSTOM STYLING TO ADD A SCROLL BAR STARTS

.add-scrollbar-with-large-height {
  max-height: 89vh;
  overflow: auto;
}

.add-scrollbar-with-small-height {
  height: 37vh;
  overflow: auto;
}

// CUSTOM STYLING TO ADD A SCROLL BAR ENDS

// CUSTOM STYLING FOR NAV TAB LINK STARTS

.nav-tab-link {
  background-color: transparent !important;
}

// CUSTOM STYLING FOR NAV TAB LINK ENDS

// INVOICE DETAIL PAGE STYLING STARTS

.invoiceDetailPage__mainCard__header__title {
  font-size: 25px;
}

.invoiceDetailPage__addressCard__infoContainer__badgeContainer__badge {
  width: 100px;
}

.invoiceDetailPage__mainCard__paidBadge {
  color: #283046;
  padding-top: 2px;
  padding-bottom: 2px;
  font-weight: bold;
}

.invoiceDetailPage__calculationTableContainer {
  overflow-x: auto;
}

.invoiceDetailPage__calculationTableContainer tr:nth-child(even) {
  background-color: transparent !important;
}

// INVOICE DETAIL PAGE STYLING ENDS

.creditCardDetails {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.creditCardInputContainer {
  padding: 0 5px;
  border-radius: 5px;
}

.creditCardInput {
  border: none;
  outline: none;
  padding: 10px;
  background: transparent;
  width: 100%;
}

.creditCardInputContainer:focus-within {
  border: 1px solid $primary !important;
}

.customDurationPicker {
  width: 100%;
  border-radius: 5px;
  padding: 5px 5px 0px 5px;
}

.customDurationPicker:focus-within {
  border: 1px solid $primary !important;
}

body .MuiInputBase-input {
  color: #202124 !important;
}

body.dark-layout .MuiInputBase-input {
  color: #b2b5bc !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid $primary;
}

.MuiButton-textPrimary {
  color: $primary !important;
}

.MuiToolbar-root {
  background-color: $primary !important;
}

.MuiFormLabel-root.Mui-focused {
  color: $primary !important;
}

body .MuiTypography-root {
  color: #252932 !important;
}

body.dark-layout .MuiTypography-root {
  color: #fff !important;
}

body .MuiDialogContent-root {
  background-color: #fff;
}

body.dark-layout .MuiDialogContent-root {
  background-color: #283046;
}

body .MuiDialogActions-root {
  background-color: #fff;
}

body.dark-layout .MuiDialogActions-root {
  background-color: #283046;
}

.loading-block {
  height: 300px;
}

.loading-block-sm {
  height: 200px;
}

.text-cyan {
  color: cyan !important;
}

.one-time-payment-stepper-container {
  min-height: 650px !important;
}

.MuiSlider-mark {
  height: 20px !important;
}

.MuiSlider-track::before {
  content: "";
  width: 20px !important;
  height: 2px !important;
  background-color: $primary !important;
  position: absolute;
  margin-left: -20px;
  border-bottom-left-radius: 1px;
  border-top-left-radius: 1px;
}

.py-2px {
  padding-top: 2px;
  padding-bottom: 2px;
}

.ml-2px {
  margin-left: 2px;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}

.MuiInput-underline:after {
  border-bottom: none !important;
}

.MuiDialogContent-root .MuiInput-underline:before {
  border-bottom: 1px solid gray !important;
}

.ml-3px {
  margin-left: 3px;
}

.swal2-styled.swal2-confirm {
  background-color: var(--bs-primary) !important;
}

.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}

// Styling for Swal starts

.dark-layout {
  .swal2-container.swal2-center > .swal2-popup {
    background-color: #161d31 !important;
  }

  .swal2-input {
    color: white !important;
  }
}

// Styling for Swal ends

.dataTable_filters_field {
  min-width: 10rem;
}

// Styling for react-select starts

.dark-layout {
  .css-1pndypt-Input {
    color: white !important;
  }
}

// Styling for react-select ends

.p-5px {
  padding: 5px !important;
}

.revenue_filter_section_header {
  // background-color: #274e13;
  background-color: var(--bs-success);
}

.revenue_filter_section_header__title {
  color: white !important;
}

.utilization_filter_section_header {
  background-color: #20124d;
}

.utilization_filter_section_header__title {
  color: white !important;
}

.visitTime_filter_section_header {
  background-color: #7f6000;
}

.visitTime_filter_section_header__title {
  color: white !important;
}

.drivers_filter_section_header {
  background-color: #134f5c;
}

.drivers_filter_section_header__title {
  color: white !important;
}

.variableRatePerformance_filter_section_header {
  background-color: #9e333e;
}

.variableRatePerformance_filter_section_header__title {
  color: white !important;
}

.utilization_meter_card {
  height: 370px !important;
}

.utilization_table_card {
  height: 370px !important;
  overflow-y: auto;
}

.utilization_table {
  height: 200px !important;
  overflow-y: auto;
}

.h-400px {
  height: 400px;
  overflow-y: auto;
}

.h-300px {
  height: 300px;
  overflow-y: auto;
}

.link-styles {
  color: $primary;
  font-weight: bold;
  cursor: pointer;
}

.dateRangeContainerInFilters {
  margin-left: auto;
  margin-right: 30px !important;
}

.bg-cyan {
  background-color: #00ffff !important;
}

.text-black {
  color: #000000 !important;
}

// Google Location API styling starts

.pac-container {
  z-index: 999999 !important;
}

// Google Location API styling ends

// Date picker styling starts

.datepicker-text {
  position: absolute;
  top: 2px;
  height: 90%;
  margin: 0px 0px 0px 5px;
  padding: 0px 8px;
  width: 150px;
  background-color: white;
}

.datepicker-text-readonly {
  position: absolute;
  top: 2px;
  height: 90%;
  margin: 0px 0px 0px 5px;
  padding: 0px 8px;
  width: 150px;
  background-color: #f7f7f7;
  color: rgba($color: #928f8f, $alpha: 1);
}

.datetimepicker-text {
  position: absolute;
  top: 2px;
  height: 90%;
  margin: 0px 0px 0px 5px;
  padding: 0px 8px;
  width: 160px;
  background-color: white;
}

.dark-layout {
  .datepicker-text {
    background-color: #283046;
  }

  .datepicker-text-readonly {
    background-color: #283046;
  }

  .datetimepicker-text {
    background-color: #283046;
  }
}

// Date picker styling ends

// Change calendar icon color in input field styling starts

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.dark-layout {
  input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    opacity: 0.6;
    filter: invert(0.8);
  }

  input[type="date"]::-webkit-calendar-picker-indicator:hover {
    opacity: 1;
  }

  input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    opacity: 0.6;
    filter: invert(0.8);
  }

  input[type="datetime-local"]::-webkit-calendar-picker-indicator:hover {
    opacity: 1;
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    opacity: 0.6;
    filter: invert(0.8);
  }

  input[type="time"]::-webkit-calendar-picker-indicator:hover {
    opacity: 1;
  }
}

// Change calendar icon color in input field styling starts

// custom label styles starts

.custom-label-styles {
  font-weight: normal !important;
  font-size: 12px;
  color: #afb3b8 !important;
}

// custom label styles ends

.notification_settings_category_section {
  background-color: #d5d2d0;
}

.dark-layout {
  .notification_settings_category_section {
    background-color: #2c3e50;
  }
}

.unread_notification_message_container {
  background-color: #e3e3f0;
}

.dark-layout {
  .unread_notification_message_container {
    background-color: #1c2241;
  }
}

// Apexcharts styling starts

.apexcharts-title-text {
  display: none !important;
}

.apexcharts-subtitle-text {
  display: none !important;
  overflow-wrap: break-word !important;
}

// Apexcharts styling ends

.text-red {
  color: red !important;
}

.chart-toolbar-custom-icon {
  margin: 5px;
  margin-bottom: 7px;
}

.swal2-validation-message {
  background-color: transparent !important;
  color: red !important;
}

.swal2-validation-message::before {
  background-color: red !important;
}

.swal2-icon.swal2-error {
  color: red !important;
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  background-color: red !important;
}

.swal2-icon.swal2-error {
  border-color: red !important;
}

.swal2-file.swal2-inputerror,
.swal2-input.swal2-inputerror,
.swal2-textarea.swal2-inputerror {
  border-color: red !important;
}

.swal2-file,
.swal2-input,
.swal2-textarea {
  box-shadow: none !important;
}

.swal2-file:focus,
.swal2-input:focus,
.swal2-textarea:focus {
  box-shadow: none !important;
}

.mt-30px {
  margin-top: 30px;
}

.report_results--headerRow {
  background-color: #d5dbdb;
}

.report_results--badge {
  background-color: #469bd5 !important;
  color: #283046 !important;
}

.report_results--compareBadge {
  background-color: #00ffff !important;
  color: #283046 !important;
}

.report_results--avgRow {
}

.dark-layout {
  .report_results--headerRow {
    background-color: #808b96;
  }

  .report_results--headerRow > th {
    color: whitesmoke !important;
  }
}

.mt-25px {
  margin-top: 25px !important;
}

.orderFob_quantity_div {
  margin-top: 35px !important;
}

// styling of placeholder of react select below

.css-14el2xx-placeholder {
  color: #bebec7 !important;
}

.dark-layout {
  .css-14el2xx-placeholder {
    color: #5c6374 !important;
  }
}

.ms-3px {
  margin-left: 3px !important;
}

input.form-control,
select.form-select,
textarea.form-control {
  margin-right: 10px !important;
}

// apexcharts styling

.apexcharts-legend {
  position: absolute !important;
  height: 50px;
}

.invalid-feedback {
  color: red !important;
  font-size: 1rem !important;
}

.is-invalid {
  border-color: red !important;
}

.theme-toggler-button {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  padding: 0.8rem;
  border-radius: 50%;
  box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999 !important;
}

.dark-layout {
  // .izzqyT:hover {
  //   color: rgba(0, 0, 0, 0.87);
  //   background-color: #161d31 !important;
  //   -webkit-transition-duration: 0.15s;
  //   transition-duration: 0.15s;
  //   -webkit-transition-property: background-color;
  //   transition-property: background-color;
  //   border-bottom-color: #161d31;
  //   outline-style: solid;
  //   outline-width: 1px;
  //   outline-color: #161d31;
  // }

  // .fFGrAy {
  //   color: rgba(0, 0, 0, 0.87);
  //   background-color: #31394fdc !important;
  //   -webkit-transition-duration: 0.15s;
  //   transition-duration: 0.15s;
  //   -webkit-transition-property: background-color;
  //   transition-property: background-color;
  //   border-bottom-color: #31394fdc;
  //   outline-style: solid;
  //   outline-width: 1px;
  //   outline-color: #31394fdc;
  // }

  // .fFGrAy:hover {
  //   color: rgba(0, 0, 0, 0.87);
  //   background-color: #161d31 !important;
  //   -webkit-transition-duration: 0.15s;
  //   transition-duration: 0.15s;
  //   -webkit-transition-property: background-color;
  //   transition-property: background-color;
  //   border-bottom-color: #161d31;
  //   outline-style: solid;
  //   outline-width: 1px;
  //   outline-color: #161d31;
  // }

  .rdt_TableRow:nth-child(even) {
    color: rgba(0, 0, 0, 0.87);
    background-color: #31394fdc !important;
    -webkit-transition-duration: 0.15s;
    transition-duration: 0.15s;
    -webkit-transition-property: background-color;
    transition-property: background-color;
    border-bottom-color: #31394fdc;
    outline-style: solid;
    outline-width: 1px;
    outline-color: #31394fdc;
  }

  .rdt_TableRow:nth-child(even):hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: #161d31 !important;
    -webkit-transition-duration: 0.15s;
    transition-duration: 0.15s;
    -webkit-transition-property: background-color;
    transition-property: background-color;
    border-bottom-color: #161d31;
    outline-style: solid;
    outline-width: 1px;
    outline-color: #161d31;
  }

  .rdt_TableRow:nth-child(odd):hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: #161d31 !important;
    -webkit-transition-duration: 0.15s;
    transition-duration: 0.15s;
    -webkit-transition-property: background-color;
    transition-property: background-color;
    border-bottom-color: #161d31;
    outline-style: solid;
    outline-width: 1px;
    outline-color: #161d31;
  }
}

.profile-details-card-body {
  height: 75vh;
  overflow-y: auto;
}

.revenue-value-chart-card {
  height: 135px;
  overflow-y: auto;
}

.visit-time-value-chart-card {
  height: 135px;
  overflow-y: auto;
}

.select-preferred-operators-data-table-search-nav-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.google-like-search-container {
  display: flex;
  align-items: center;
  border: 1px solid #dfe1e5;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(32, 33, 36, 0.1);
  width: 600px;
}

.google-like-search-input {
  flex: 1;
  font-size: 16px;
  border: none;
  outline: none;
}

.otp-code-card-cross-button {
  position: absolute;
  right: -10px;
  top: -10px;

  &:hover {
    cursor: pointer;
    scale: 1.1;
    transition: all 0.2s ease-in;
  }

  &:active {
    scale: 1;
    transition: all 0.2s ease-out;
  }
}

.swal2-popup {
  background-color: #f8f8f8 !important;
}

.dark-layout {
  .swal2-popup {
    background-color: #161d31 !important;
  }
}



// Responsiveness Breakpoints below

@media (max-width: 1300px) {
  .select-preferred-operators-data-table-search-nav-container {
    position: relative;
    left: 0;
    transform: translateX(0);
    margin-left: 20px;
  }
}

@media (max-width: 1200px) {
  .creditCardDetails {
    width: 75%;
  }
}

@media (max-width: 900px) {
  .creditCardDetails {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .orderFob_quantity_div {
    margin-top: 10px !important;
  }
}

@media (max-width: 812px) {
  .orderFob_quantity_div {
    margin-top: 30px !important;
  }
}

@media (max-width: 768px) {
  .sm-mt-5 {
    margin-top: 50px;
  }
}

@media (max-width: 750px) {
  .google-like-search-container {
    width: 100%;
  }

  .google-like-search-input {
    &::placeholder {
      font-size: 12px;
    }
  }
}
